/* Navbar Styling */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 3rem;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
    font-family: 'Georgia', serif;
}

.brand-logo {
    font-size: 2rem;
    text-decoration: none;
    font-weight: 700;
    color: #007bff;
    letter-spacing: 1.5px;
    transition: transform 0.3s ease;
}

.nav-links {
    list-style-type: none;
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-grow: 1;
}

.nav-links a {
    color: #fff;
    text-decoration: none;
    font-size: 1.1rem;
    font-weight: 500;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.nav-links a:hover {
    background-color: rgba(255, 255, 255, 0.2);
    color: #007bff;
}

.register-btn, .logout-btn {
    font-family: 'Georgia', serif;
    font-size: 1.1rem;
    font-weight: bold;
    padding: 0.7rem 2rem;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
}

.register-btn {
    background-color: #3c3c3c;
    color: #007bff;
}

.register-btn:hover {
    background-color: #3c3c3c;
    transform: scale(1.05);
}

.register-btn:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.8);
}

.logout-btn {
    font-family: 'Georgia', serif;
    background: linear-gradient(145deg, #e74c3c, #c0392b);
    color: #fff;
}

.logout-btn:hover {
    background: linear-gradient(145deg, #c0392b, #e74c3c);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    filter: brightness(1.1);
}

.logout-btn:focus {
    outline: none;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.8);
}

/* Modal Styling */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #2c2c2c;
    padding: 2rem;
    border-radius: 20px;
    text-align: center;
    width: 300px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-content h2 {
    margin-bottom: 1.5rem;
}

.modal-content button {
    margin-top: 1rem;
    
    color: #fff;
}



@media (max-width: 768px) {
    .register-btn, .logout-btn {
        padding: 0.6rem 1.5rem;
        font-size: 1rem;
    }
    .navbar {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .register-btn {
        margin-top: 1rem;
    }
}

@media (max-width: 1024px) {
    .navbar {
        padding: 1rem;
        justify-content: center;
    }

    .nav-links {
        display: none;
    }

    .brand-logo {
        font-size: 1.8rem;
        display: block;
        text-align: center;
    }
}
@media (max-width: 768px) {
    .modal-content {
        width: 90%; /* تكييف العرض */
        padding: 1.5rem; /* تقليل الحواف */
        border-radius: 15px; /* تقليل نصف القطر */
    }

    .modal-content h2 {
        font-size: 1.2rem; /* تقليل حجم الخط */
        margin-bottom: 1rem;
    }
    

    .modal-content button {
        font-size: 1rem; /* تقليل حجم الخط للأزرار */
        padding: 0.5rem 1.5rem; /* تقليل الحواف */
    }
}
/* تحسين تصميم زر Logout للشاشات الصغيرة */
@media (max-width: 768px) {
    .logout-btn {
        font-size: 1rem; /* تقليل حجم النص */
        padding: 0.5rem 1.5rem; /* تقليل المسافة الداخلية */
        border-radius: 8px; /* تقليل حواف الزر */
        width: 100%; /* جعل الزر يمتد ليملأ العرض بالكامل إذا لزم */
    }

    .logout-btn:hover {
        filter: brightness(1.1); /* الاحتفاظ بتأثير التمرير */
    }

    .modal-content button {
        margin-top: 1rem; /* مسافة كافية بين الزر وبقية محتويات المودال */
    }
}

.no-payment-required {
    color: #f44336;
    font-weight: bold;
    margin-top: 0.5rem;
    display: block;
  }


