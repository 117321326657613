@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&family=Roboto:wght@400;500&display=swap');

/* CSS Variables */
:root {
    --bg-color: #1a1a1a;
    --light-color: #e0e0e0;
    --dark-gray: #2c2c2c;
    --hover-color: #3c3c3c;
    --border-color: #007bff;
    --green-color: #4caf50; 
    --red-color: #f44336;
    --blue-color: #2196f3; 
}
 


/* Body */
body {
    background-color: var(--bg-color);
    margin: 0;
    padding: 0;
    font-family: 'Roboto', Georgia;
    color: var(--light-color);
    max-width: 100%;
}

th
{
    font-family: 'Georgia', serif;
}
h2{
    font-family: 'Georgia', serif;
}




body, html {
    width: 100vw;
    overflow-x: hidden; /* منع التمرير الأفقي */
    margin: 0;
    padding: 0;
}



/* Dashboard */
.dashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0px;
    background-color: var(--bg-color);
    border-radius: 5px;
    
    max-width: 100%;
    width: 95vw;
    margin: auto;
}

/* Ticker */
.ticker {
    background-color: var(--dark-gray);
    padding: 10px;
    margin: 0px 0;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    overflow: hidden;
    color: #2c2c2c; /* اجعل لون النص أزرق */
    
    
}

.ticker marquee {
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    color: var(--border-color);
    color: #2c2c2c; /* اجعل لون النص أزرق */
}

/* إعدادات اللوغو */
.coin-logo {
    width: 30px;
    height: 30px;
}

/* Squares Container using CSS Grid */
.squares-container {
    display: grid;
    grid-template-columns: repeat(8, 1fr); /* 4 أعمدة للحواسيب */
    gap: 20px;
    margin-top: 20px;
    width: 100%;
}

.squares-container-base {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 أعمدة للحواسيب */
    gap: 20px;
    margin-top: 20px;
    width: 100%;
}

.square {
    font-family: 'Georgia', serif;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
    background-color: var(--dark-gray);
    transition: transform 0.2s;
    color: var(--light-color);
    border: 2px solid transparent;
    text-align: center;
    position: relative;
    font-size: 20px;
    
}


.square::after {
    content: "!";
    position: absolute;
    top: 0.5em; /* المسافة من الأعلى */
    right: 0.5em; /* المسافة من اليمين */
    color: #e0e0e0; /* لون علامة التعجب */
    font-size: 0.8em; /* حجم علامة التعجب */
    font-weight: bold; /* جعل علامة التعجب عريضة */
    
    /* تصميم الدائرة */
    width: 0.9em; /* عرض الدائرة */
    height: 0.9em; /* ارتفاع الدائرة */
    border: 2px solid #e0e0e0; /* لون وإطار الدائرة */
    border-radius: 50%; /* جعل الشكل دائريًا */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent; /* لون الخلفية داخل الدائرة */
    
}

.square-base {
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
    background-color: var(--dark-gray);
    transition: transform 0.2s;
    color: var(--light-color);
    border: 2px solid transparent;
    text-align: left;
    position: relative;
    font-size: 20px;
    
}

.square-base::after {
    content: "!";
    position: absolute;
    top: 0.7em; /* المسافة من الأعلى */
    right: 0.7em; /* المسافة من اليمين */
    color: #007bff; /* لون علامة التعجب */
    font-size: 0.8em; /* حجم علامة التعجب */
    font-weight: bold; /* جعل علامة التعجب عريضة */
    
    /* تصميم الدائرة */
    width: 0.9em; /* عرض الدائرة */
    height: 0.9em; /* ارتفاع الدائرة */
    border: 2px solid #007bff; /* لون وإطار الدائرة */
    border-radius: 50%; /* جعل الشكل دائريًا */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent; /* لون الخلفية داخل الدائرة */
    
}

.square-base-btc {
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
    background-color: var(--dark-gray);
    transition: transform 0.2s;
    color: var(--light-color);
    border: 2px solid transparent;
    text-align: center;
    position: relative;
    font-size: 1em;
    
}

.square:hover {
    transform: scale(1.05);
    background-color: var(--hover-color);
    border: 2px solid var(--border-color);
}

.value-container-base {
    margin-top: 1em ;
    display: center;
    align-items: center;
    /*justify-content: space-between;*/
    font-size: 35px;
}



.buy {
    border-left: 5px solid var(--green-color);
}

.sell {
    border-left: 5px solid var(--red-color);
}

.hold {
    border-left: 5px solid var(--blue-color);
}

.btc-logo {
    margin-right:1em ;
    width: 100px;
    height: 100px;
    margin-bottom: 0px;
}

h2-base {
        font-family: 'Georgia', serif;
        margin-top: 0.67em;      /* المسافة العلوية */
        margin-bottom: 0.67em;   /* المسافة السفلية */
        font-size: 1.2em;          /* حجم الخط */
        font-weight: bold;       /* وزن الخط عريض */
        line-height: 0;        /* ارتفاع السطر */
        text-align: left;        /* محاذاة النص لليسار */
     }




     
    
/* Responsive Design */
/* الحواسيب اللوحية */
@media (max-width: 1024px) {
    .ticker marquee {
        font-size: 0.8em;
    }


    .coin-logo {
        width: 22px;
        height: 22px;
    }
    
    
    .squares-container {
        grid-template-columns: repeat(4, 1fr); /* عمودين فقط للتابلت والهواتف */
        font-size: 10px;
        gap: 10px;
    }
    .squares-container-base {
        display: grid;
        grid-template-columns: repeat(4, 1fr); /* 4 أعمدة للحواسيب */
        gap: 10px;
        margin-top: 20px;
        width: 100%;
    }
    .btc-logo {
        margin-left:0.6em ;
        margin-top: 0.2em;
        width: 60px;
        height: 60px;
    }
    .value-container {
        display: center;
        align-items: center;
        /*justify-content: space-between;*/
        font-size: 16px;
    }

    .value-container-base {
        
        font-size: 1.5em;
    }

    .square {
        padding: 0px;
        font-size: 1.5em;
        text-align: center;
    }

    .square-base {
        padding: 20px;
        font-size: 15px;
        text-align: center;
    }
    .square-base-btc {
        display: block;
        align-items: center;
        border-radius: 5px;
        padding: 3px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
        background-color: var(--dark-gray);
        transition: transform 0.2s;
        color: var(--light-color);
        border: 2px solid transparent;
        text-align: center;
        position: relative;
        font-size: 1em;
        
    }

    
}

/* الهواتف المحمولة الكبيرة */
@media (max-width: 768px) {
    .ticker marquee {
        font-size: 0.8em;
    }


    .coin-logo {
        width: 22px;
        height: 22px;
    }
    
    
    .squares-container {
        grid-template-columns: repeat(4, 1fr); /* عمودين فقط للتابلت والهواتف */
        font-size: 10px;
        gap: 10px;
    }
    .squares-container-base {
        display: grid;
        grid-template-columns: repeat(4, 1fr); /* 4 أعمدة للحواسيب */
        gap: 10px;
        margin-top: 20px;
        width: 100%;
    }
    .btc-logo {
        margin-left:0.6em ;
        margin-top: 0.2em;
        width: 60px;
        height: 60px;
    }
    .value-container {
        display: center;
        align-items: center;
        /*justify-content: space-between;*/
        font-size: 16px;
    }

    .value-container-base {
        margin-top: 0.5em ;
        font-size: 1.5em;
    }

    .square {
        padding: 0px;
        font-size: 1.4em;
        text-align: center;
    }

    .square-base {
        padding: 20px;
        font-size: 15px;
        text-align: center;
    }
    .square-base-btc {
        display: block;
        align-items: center;
        border-radius: 5px;
        padding: 3px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
        background-color: var(--dark-gray);
        transition: transform 0.2s;
        color: var(--light-color);
        border: 2px solid transparent;
        text-align: center;
        position: relative;
        font-size: 1em;
        
    }

    h2-base {
        margin: 0;
        font-weight: 555;
        font-size: 1.2em;
        flex:1;
        text-align: left; /* محاذاة العنوان لليسار */
        line-height: 1.4; 
         }

         h2{
        
            font-weight: 555;
            
             }
    

    
}

/* الهواتف المحمولة الصغيرة */
@media (max-width: 480px) 

{

    

    
    .squares-container {
        grid-template-columns: repeat(4, 1fr); /* عمود واحد على الهواتف الصغيرة */
        font-size: 10px;
        gap: 10px;
    }

    .squares-container-base {
        display: grid;
        grid-template-columns: repeat(1, 1fr); /* 4 أعمدة للحواسيب */
        gap: 20px;
        margin-top: 20px;
        width: 100%;
    }

    .square {
        border-radius: 5px;
        padding: 1px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
        background-color: var(--dark-gray);
        transition: transform 0.2s;
        color: var(--light-color);
        border: 2px solid transparent;
        text-align: center;
        position: relative;
        font-size: 1.2em;
        
    }

    .square::after {
        content: "!";
        position: absolute;
        top: 0.2em; /* المسافة من الأعلى */
        right: 0.2em; /* المسافة من اليمين */
        color: #e0e0e0; /* لون علامة التعجب */
        font-size: 0.8em; /* حجم علامة التعجب */
        font-weight: bold; /* جعل علامة التعجب عريضة */
        
        /* تصميم الدائرة */
        width: 0.9em; /* عرض الدائرة */
        height: 0.9em; /* ارتفاع الدائرة */
        border: 2px solid #e0e0e0; /* لون وإطار الدائرة */
        border-radius: 50%; /* جعل الشكل دائريًا */
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent; /* لون الخلفية داخل الدائرة */
        
    }

    


    .square-base {
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
    background-color: var(--dark-gray);
    transition: transform 0.2s;
    color: var(--light-color);
    border: 2px solid transparent;
    text-align: center;
    position: relative;
    font-size: 1em;
    
    }

    .square-base::after {
        content: "!";
        position: absolute;
        top: 0.7em; /* المسافة من الأعلى */
        right: 0.7em; /* المسافة من اليمين */
        color: #007bff; /* لون علامة التعجب */
        font-size: 0.8em; /* حجم علامة التعجب */
        font-weight: bold; /* جعل علامة التعجب عريضة */
        
        /* تصميم الدائرة */
        width: 0.9em; /* عرض الدائرة */
        height: 0.9em; /* ارتفاع الدائرة */
        border: 2px solid #007bff; /* لون وإطار الدائرة */
        border-radius: 50%; /* جعل الشكل دائريًا */
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent; /* لون الخلفية داخل الدائرة */
        
    }


    .square-base-btc {
        display: flex;
        align-items: center;
        border-radius: 5px;
        padding: 1px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
        background-color: var(--dark-gray);
        transition: transform 0.2s;
        color: var(--light-color);
        border: 2px solid transparent;
        text-align: center;
        position: relative;
        font-size: 1em;
        
    }

    h2{
        
        font-weight: 555;
        
        
         }

    
    h2-base {
    margin: 0;
    font-weight: 555;
    font-size: 1em;
    flex:1;
    text-align: left; /* محاذاة العنوان لليسار */
    line-height: 1.4; 
     }

    

    
    .value-container-base {
        padding: 0.3em;
        margin-top: 0em ;
        font-size: 1.3em;
        text-align: center; /* توسيط النسبة */
        flex: 1; /* اجعل النسبة تشغل المساحة المتاحة في المنتصف */
      }

      

    .square:hover {
        transform: scale(1.05);
        background-color: var(--hover-color);
        border: 2px solid var(--border-color);
    }



    .buy {
        border-left:3px solid var(--green-color);
    }

    .sell {
        border-left: 3px solid var(--red-color);
    }

    .hold {
        border-left: 3px solid var(--blue-color);
    }

    .btc-logo {
        width: 50px;
        height: 50px;
        margin-bottom: 0px;
    }

    

    .ticker {
        padding: 7px;
        margin: 0px;
        font-size: 24px;
        height: 30px;
        max-width: 100%;
        width: 92vw;
        line-height: 1.5;
    }
    

    .ticker marquee {
        font-size: 0.65em;
    }

    .coin-logo {
        width: 25px;
        height:25px;
    }
}













/* Data Table Styling */
.data-table {
    margin-top: 20px;
    background-color: var(--dark-gray);
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    
    width: 100%;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    table-layout: auto;
}

th, td {
    padding: 10px;
    text-align: center;
    border: 1px solid #444;
    color: var(--light-color);
    word-wrap: break-word;
    min-width: 120px;
    height: 50px;
    font-size: 1.2em;
}

th {
    background-color: var(--hover-color);
    cursor: pointer;
    position: sticky;
    top: 0;
    z-index: 1;
}

tbody tr:hover {
    background-color: var(--hover-color);
}

/* Responsive Design for Data Table */
@media (max-width: 768px) {
    .data-table {
        padding: 10px;
    }

   
    th, td {
        padding: 15px;
        font-size: 1em;
        text-align: center;
        border: 1px solid #444;
        color: var(--light-color);
        word-wrap: break-word;
        min-width: 0px;
        height: 50px;
    }
}

@media (max-width: 480px) {
    .data-table {
        padding: px;
    }

    th, td {
        padding: 11px;
        font-size: 1em;
        text-align: center;
        border: 1px solid #444;
        color: var(--light-color);
        word-wrap: break-word;
        min-width: 0px;
        height: 50px;
    }
}

/* Sidebar Styling */
.sidebar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 20px 0;
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
}

.sidebar li {
    margin: 5px 0;
    color: var(--light-color);
    cursor: pointer;
    transition: color 0.3s;
}

.sidebar li:hover {
    color: var(--border-color);
}

/* Header */
.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
    padding: 0 20px;
}

.header-container h1 {
    margin: 0;
    font-size: calc(1.5rem + 1vw);
    color: var(--light-color);
}



/* Pagination */
.pagination {
    display: flex;
    justify-content: center;
    padding: 15px 0;
}

.pagination button {
    padding: 8px 16px;
    margin: 0 5px;
    border: none;
    border-radius: 5px;
    background-color: var(--dark-gray);
    color: var(--light-color);
    cursor: pointer;
    transition: background-color 0.3s;
}

.pagination button:hover {
    background-color: var(--hover-color);
}
.login-btn {
    background-color: transparent;
    color: #3498db;
    border: 1px solid #3498db;
  }
  
  .login-btn:hover {
    background-color: #3498db;
    color: #fff;
  }
  
  .signup-btn {
    background-color: #007bff;
    color: #fff;
    border: none;
  }
  
  .signup-btn:hover {
    background-color: #007bff;
  } 

  /* Pagination */
.pagination {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.pagination button {
    padding: 10px 15px;
    border: none;
    background-color: var(--border-color);
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

.pagination button:disabled {
    background-color: #2c2c2c;
    cursor: not-allowed;
}

/* Ticker Style */
.ticker span {
    color: var(--border-color);
    font-weight: 600;
}

/* Filter Input Styling */
.filter-input {
    font-family: 'Georgia', serif;
    padding: 10px 15px;
    border: 2px solid var(--border-color);
    border-radius: 5px;
    font-size: 1.1em;
    color: var(--light-color);
    background-color: var(--dark-gray);
    transition: border-color 0.3s, box-shadow 0.3s;
    text-align: center;
    
    
}

.filter-input:focus {
    outline: none;
    border-color: var(--blue-color);
    box-shadow: 0 0 5px var(--blue-color);
}

/* إعدادات الخلية */
.coin-cell {
    display: flex;
    align-items: center;
    justify-content: space-between; /* توزيع العناصر بين اليسار واليمين */
    padding-left: 10px;
    padding-right: 10px;
}




/* تنسيق النص */
.coin-name {
    font-family: 'Georgia', serif;font-family: 'Georgia', serif;
    flex: 1; /* يجعل النص في المنتصف */
    text-align: center;
}




/* Main navbar styling */
.navbar {
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 3rem;
    
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
    
}

/* Logo styling */
.brand-logo {
    font-size: 1.8rem;
    font-weight: 700;
    color: #007bff;
    font-family: 'Georgia', serif;
    letter-spacing: 1.5px;
    transition: transform 0.3s ease;
}

.brand-logo:hover {
    transform: scale(1.1);
}

/* Navbar links styling */
.nav-links {
    list-style-type: none;
    display: flex;
    justify-content: center; /* Center the links */
    gap: 2rem;
    flex-grow: 1; /* Allow this to take available space */
}

.nav-links a {
    color: #fff;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.nav-links a:hover {
    background-color: rgba(255, 255, 255, 0.2);
    color: #007bff;
}

/* Auth links styling */
.auth-links {
    display: flex;
    gap: 1rem; /* Space between Sign In and Sign Up */
}

.auth-links a {
    color: #fff;
    text-decoration: none;
    font-weight: 500;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.auth-links a:hover {
    background-color: rgba(255, 255, 255, 0.2);
    color: #007bff;
}

/* Responsive layout for smaller screens */
@media (max-width: 1024px) {
    .navbar {
        padding: 1rem; /* Less padding for smaller screens */
        justify-content: center; /* Center all items */
    }

    /* Hide navigation links and auth links on mobile */
    .nav-links,
    .auth-links {
        display: none; /* Hide the links */
    }

    /* Show only the brand logo */
    .brand-logo {
        
        display: block;
        text-align: center; /* Center the logo */
        width: 100%; /* Make logo occupy full width */
    }
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }
  
  .modal-content {
    font-size: 20px;
    background: var(--dark-gray);
    padding: 30px;
    border-radius: 15px;
    width: 300px;
    max-width: 50%;
    color: var(--light-color);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
    position: relative;
    text-align: center;
    animation: slide-down 0.4s ease;
  }
  
  .close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    color: var(--light-color);
    font-size: 1.2em;
    cursor: pointer;
  }
  
  .close-button:hover {
    color: var(--border-color);
  }
  
  @keyframes slide-down {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }


  @media (max-width: 480px) {
    .modal-content {
        font-size: 14px;
        background: var(--dark-gray);
        padding: 30px;
        border-radius: 15px;
        width: 1000px;
        max-width: 50%;
        max-height: 50%;
        color: var(--light-color);
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
        position: relative;
        text-align: center;
        animation: slide-down 0.4s ease;
      }

      

      
}



.ticker-container {
    width: 100%;
    overflow: hidden;
    position: relative;
    
    padding: 10px;
    margin: 0px 0;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    
    
    
}

.ticker-text {
    display: inline-block;
    white-space: nowrap;
    animation: scroll-left 90s linear infinite; /* تعيين الحركة المستمرة */
}

@keyframes scroll-left {
    from {
        transform: translateX(-100%); /* يبدأ النص خارج الشاشة من اليمين */
    }
    to {
        transform: translateX(35%); /* ينتهي النص خارج الشاشة من اليسار */
    }
}

/* تأثير سكيل (تكبير وتصغير) مع حركة سلسة */
.full-screen-updating {
    animation: scaleUpDown 2s ease-in-out; /* الحركة تكون سلسة وناعمة */
}

/* تأثير تكبير وتصغير */
@keyframes scaleUpDown {
    0% {
        transform: scale(1); /* يبدأ الحجم الطبيعي */
    }
    50% {
        transform: scale(1.01); /* تكبير طفيف */
    }
    100% {
        transform: scale(1.0); /* العودة إلى الحجم الطبيعي */
    }
}


/* جعل الجدول قابلاً للتمرير العمودي */
.table-responsive {
    overflow-x: auto; /* يسمح بالتمرير الأفقي */
    overflow-y:hidden; /* يسمح بالتمرير العمودي */
    
    
    scroll-behavior: smooth; /* يجعل التمرير أكثر سلاسة */
    transition: all 0.3s ease; /* إضافة تأثير لتحسين التفاعل */
    position: relative;
}

/* تخصيص شريط التمرير */
.table-responsive::-webkit-scrollbar {
    width: 0 /* عرض شريط التمرير */
    
}

.table-responsive::-webkit-scrollbar-thumb {
    background-color: #3c3c3c; /* لون الشريط */
    border-radius: 10px; /* تدوير حواف الشريط */
    transition: background-color 0.3s; /* إضافة تأثير عند المرور بالماوس */
    
}

.table-responsive::-webkit-scrollbar-thumb:hover {
    background-color: #555555; /* تغيير اللون عند التمرير فوقه */
}

.table-responsive::-webkit-scrollbar-track {
    background: #2c2c2c; /* لون الخلفية لشريط التمرير */
    border-radius: 10px;
}

/* إضافة تأثير الظل على الجدول عند التمرير */
.table-responsive:hover {
    box-shadow: 0px 4px 6px #007bff; /* إضافة تأثير الظل عند المرور بالماوس */
}


.subscribe-message button {
    background: linear-gradient(135deg, var(--dark-gray), var(--bg-color));
    color: var(--light-color);
    font-family: 'Georgia', serif;
    font-size: 1.2rem;
    font-weight: bold;
    padding: 0.75em 1.5em;
    border: 2px solid var(--border-color);
    border-radius: 8px;
    text-transform: uppercase;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    position: relative;
    overflow: hidden;
}

.subscribe-message button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    opacity: 0.2;
    transform: scale(0);
    transition: transform 0.4s ease-out;
    border-radius: inherit;
    z-index: 0;
}

.subscribe-message button:hover {
    background: linear-gradient(135deg, var(--hover-color), var(--bg-color));
    border-color: var(--green-color);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
}

.subscribe-message button:hover::before {
    transform: scale(2);
}

.subscribe-message button:active {
    transform: translateY(2px);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
}





  