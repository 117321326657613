.indicators-container {
    padding: 2rem;
    max-width: 900px;
    margin: 0 auto;
    color: #f5f5f5; /* تحسين وضوح النصوص */
    background: #1a1a1a; /* خلفية داكنة */
    border-radius: 12px; /* حواف ناعمة */
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.3); /* تأثير الظل العميق */
    font-family: 'Georgia', serif;
}

.indicators-header {
    text-align: center;
    margin-bottom: 2rem;
}

.indicators-header h1 {
    font-size: 2.8rem;
    color: #007bff; /* لون أخضر عصري يعكس الإيجابية */
    margin-bottom: 0.5rem;
}

.intro-text {
    font-size: 1.2rem;
    color: #ddd; /* تباين مع الخلفية الداكنة */
    margin-bottom: 2rem;
}

.indicator-item {
    margin-bottom: 1.8rem;
    padding: 1.5rem;
    border-left: 4px solid #007bff; /* اللون الأخضر المميز */
    background: #333; /* خلفية داكنة لكل عنصر */
    border-radius: 8px;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* تأثيرات عند التمرير */
}

.indicator-item:hover {
    transform: translateY(-8px); /* تحريك العنصر للأعلى عند التمرير */
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.4); /* تأثير الظل عند التمرير */
}

.indicator-item h2 {
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
    color: #007bff; /* نفس اللون الأخضر للعناوين */
}

.indicator-item p {
    font-size: 1rem;
    color: #ddd; /* تباين مع الخلفية */
}

.indicators-footer {
    text-align: center;
    margin-top: 2rem;
    font-weight: bold;
    font-size: 1.1rem;
    color: #ddd; /* اللون المتناسق مع الخلفية الداكنة */
}
