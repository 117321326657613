/* src/Spinner.css */

.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh; /* Center spinner in the viewport */
    max-width: 100%;
    width: 80vw;
    margin: auto;
}

.spinner-circle {
    width: 40px;
    height: 40px;
    border: 4px solid rgba(255, 255, 255, 0.3); /* Light color for background */
    border-top: 4px solid #3498db; /* Color for the animated part */
    border-radius: 50%;
    animation: spin 1s linear infinite; /* Continuous spinning animation */
}

@keyframes spin {
    0% {
        transform: rotate(0deg); /* Start position */
    }
    100% {
        transform: rotate(360deg); /* End position */
    }
}
