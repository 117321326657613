.terms-container {
    padding: 2rem;
    max-width: 800px;
    margin: 0 auto;
    color: #f5f5f5;
    background-color: #1a1a1a;
    border-radius: 10px;
    font-family: 'Georgia', serif;
  }
  
  .terms-container h1 {
    font-size: 2.5rem;
    color: #007bff;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .terms-container h2 {
    font-size: 1.8rem;
    color: #ccc;
    margin-top: 1.5rem;
  }
  
  .terms-container p, .terms-container ul {
    font-size: 1rem;
    line-height: 1.6;
    color: #ccc;
  }
  
  .terms-container a {
    color: #007bff;
    text-decoration: none;
  }
  
  .terms-container a:hover {
    text-decoration: underline;
  }
  