.about-container {
    padding: 2rem;
    max-width: 900px;
    margin: 0 auto;
    color: #f5f5f5; /* Light text color for better contrast */
    background: #1a1a1a; /* Dark background */
    border-radius: 12px; /* Rounded corners */
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.3); /* Subtle shadow */
    font-family: 'Georgia', serif;
  }
  
  .about-header {
    font-family: 'Georgia', serif;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .about-header h1 {
    font-size: 2.8rem;
    color: #007bff; /* Bright green color for the title */
    margin-bottom: 1rem;
  }
  
  .about-intro p {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #ddd; /* Light grey text for better readability */
    margin-bottom: 1.5rem;
  }
  
  .about-vision-mission {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    margin-top: 2rem;
  }
  
  .about-vision, .about-mission {
    background: #333; /* Dark background for the vision and mission sections */
    padding: 1.5rem;
    border-radius: 8px;
    flex: 1;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .about-vision h2, .about-mission h2 {
    font-size: 2rem;
    color: #007bff; /* Green color for section titles */
    margin-bottom: 1rem;
  }
  
  .about-vision p, .about-mission p {
    font-size: 1.1rem;
    color: #ddd; /* Light grey text */
    line-height: 1.5;
  }
  
  .about-vision-mission div {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .about-vision-mission div:hover {
    transform: translateY(-5px); /* Slight movement on hover */
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.4); /* Stronger shadow on hover */
  }
  