.services-container {
    padding: 2rem;
    max-width: 800px;
    margin: 0 auto;
    color: #f5f5f5; /* تغيير اللون ليكون أكثر وضوحاً على الخلفية الداكنة */
    background: #1a1a1a; /* تحديد الخلفية الداكنة */
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* تأثير الظل على المربع */
    font-family: 'Georgia', serif;
  }
  
  .services-header {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .services-header h1 {
    font-size: 2.5rem;
    color: #007bff; /* لون مميز للعناوين */
    margin-bottom: 0.5rem;
  }
  
  .intro-text {
    font-size: 1.1rem;
    color: #ccc; /* تباين مع الخلفية الداكنة */
  }
  
  .service-item {
    margin-bottom: 1.5rem;
    padding: 1rem;
    border-left: 4px solid #007bff; /* استخدام نفس اللون المميز */
    background: #333; /* خلفية داكنة للفقرات */
    border-radius: 5px;
    transition: transform 0.2s;
  }
  
  .service-item:hover {
    transform: translateY(-5px);
  }
  
  .service-item h2 {
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
    color: #007bff; /* لون العناوين الداخلية */
  }
  
  .service-item p {
    font-size: 1rem;
    color: #ccc; /* تباين مع الخلفية الداكنة */
  }
  
  .services-footer {
    text-align: center;
    margin-top: 2rem;
    font-weight: bold;
    font-size: 1.1rem;
    color: #ccc; /* اللون المتناسق مع الخلفية الداكنة */
  }
  