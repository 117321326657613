/* Footer */
.footer {
  background-color: var(--dark-gray);
  color: var(--light-color);
  padding: 20px;
  text-align: center;
  position: relative;
  max-width: 100%;
  width: 96vw;
  margin-left: 0.1em;
  margin-top: 1em;
}
body, html {
  width: 100vw;
  overflow-x: hidden; /* منع التمرير الأفقي */
  margin: 0;
  padding: 0;
  
}

/* Footer Content */
.footer-content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 20px;
  max-width: 100%;
  font-family: 'Georgia', serif;
}

/* Footer Section */
.footer-section {
  margin: 10px;
  max-width: 100%;
  width: 30%;
}

/* Footer Section Header */
.footer-section h3 {
  font-weight: bold;
  margin-bottom: 10px;
}

/* Footer Section List */
.footer-section ul {
  list-style-type: none;
  padding: 0;
}

/* Footer List Items */
.footer-section ul li {
  margin: 5px 0;
}

/* Footer List Links */
.footer-section ul li a {
  color: var(--border-color);
  text-decoration: none;
}

/* Footer List Links Hover */
.footer-section ul li a:hover {
  text-decoration: underline;
}

/* Footer Bottom */
.footer-bottom {
  border-top: 1px solid var(--border-color);
  padding-top: 10px;
  font-size: 0.9em;
}


/* Responsive Design for Footer */
@media (max-width: 768px) {

  .footer {
    background-color: var(--dark-gray);
    color: var(--light-color);
    padding: 5px;
    text-align: center;
    position: relative;
    max-width: 100%;
    width: 95vw;
    margin-left: 0.25em;
    margin-bottom: 1em;
  }

  .footer-content {
      flex-direction: column;
      align-items: center;
  }

  .footer-section {
      text-align: center;
      width: 100%;
  }
}

.footer-section ul li.disclaimer {
  color: #f44336;
  font-size: 0.9rem; /* يمكنك تعديل الحجم إذا أردت */
  font-weight: bold; /* اختياري لجعل النص أكثر وضوحًا */
  margin: 0.5em;
}

