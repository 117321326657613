:root {
    --bg-color: #1a1a1a; /* Background color */
    --light-color: #e0e0e0; /* Text color */
    --dark-gray: #2c2c2c; /* Dark background */
    --hover-color: #3c3c3c; /* Hover background */
    --border-color: #007bff; /* Border color */
    --blue-color: #007bff; /* Blue color */
  }
  
  .payment-success-page {
    background-color: var(--bg-color);
    color: var(--light-color);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Georgia', serif;
    text-align: center;
  }
  
  .success-message {
    background-color: var(--dark-gray);
    color: var(--light-color);
    padding: 30px 50px;
    border-radius: 20px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.3);
    max-width: 500px;
    width: 90%;
    animation: fadeIn 1s ease-in-out;
  }
  
  .success-message h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: var(--blue-color); /* Blue title */
  }
  
  .success-message p {
    font-size: 1.2rem;
    line-height: 1.5;
  }
  
  .success-message a {
    color: var(--blue-color);
    font-weight: bold;
    text-decoration: none;
    border: 2px solid var(--border-color);
    padding: 5px 10px;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
  }
  
  .success-message a:hover {
    background-color: var(--hover-color);
    color: var(--light-color);
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  