:root {
  --bg-color: #1a1a1a; /* لون خلفية الصفحة */
  --light-color: #e0e0e0; /* لون النص */
  --dark-gray: #2c2c2c; /* لون الخلفية الداكن */
  --hover-color: #3c3c3c; /* لون الخلفية عند التحويم */
  --border-color: #007bff; /* لون الحدود */
  --blue-color: #2196f3; /* لون الأزرق */
}

.subscribe-page {
  font-family: 'Georgia', serif;
  background-color: var(--bg-color);
  color: var(--light-color);
  padding: 30px;
  border-radius: 12px;
  max-width: 600px;
  margin: 40px auto;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.subscribe-page h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: var(--blue-color);
  text-transform: uppercase;
}

.subscription-options {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  margin: 20px 0;
}

.subscription-options label {
  cursor: pointer;
  background-color: var(--dark-gray);
  color: var(--light-color);
  border: 2px solid var(--border-color);
  padding: 10px 20px;
  border-radius: 12px;
  font-size: 1rem;
  text-align: center;
  transition: all 0.3s ease;
  width: 150px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.subscription-options input[type="radio"] {
  display: none; /* إخفاء زر الاختيار */
}

.subscription-options input[type="radio"]:checked + label {
  background-color: var(--blue-color);
  color: var(--light-color);
  font-weight: bold;
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.subscription-options label:hover {
  background-color: var(--hover-color);
  transform: scale(1.05);
}

button {
  font-family: 'Georgia', serif;
  font-size: 1em;
  padding: 10px 30px;
  border-radius: 25px;
  background-color: var(--blue-color);
  color: var(--light-color);
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 20px auto;
}

button:hover {
  background-color: var(--hover-color);
  transform: scale(1.05);
}

.disclaimer {
  color: #f44336;
  font-size: 0.9rem; /* يمكنك تعديل الحجم إذا أردت */
  font-weight: bold; /* اختياري لجعل النص أكثر وضوحًا */
  margin: 1.5em;
  text-align: center;
  
}

a {
  color: #007bff; /* Makes the text color blue */
  text-decoration: none; /* Optional: Removes underline from the link */
}

a:hover {
  color: #2196f3; /* Optional: Changes color when the user hovers over the link */
}