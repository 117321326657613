.contact-container {
    padding: 2rem;
    max-width: 900px;
    margin: 0 auto;
    color: #f5f5f5;
    background: #1a1a1a;
    border-radius: 12px;
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.3);
    font-family: 'Georgia', serif;
  }
  
  .contact-header {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .contact-header h1 {
    font-size: 2.8rem;
    color: #007bff; /* Green color for the title */
    margin-bottom: 1rem;
  }
  
  .contact-header p {
    font-size: 1.2rem;
    color: #ddd;
    line-height: 1.6;
  }
  
  .contact-methods {
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr;
  }
  
  .contact-method {
    background: #333;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .contact-method h2 {
    font-size: 2rem;
    color: #007bff;
    margin-bottom: 1rem;
  }
  
  .contact-method p {
    font-size: 1.1rem;
    color: #ddd;
    line-height: 1.5;
  }
  
  .email-link {
    color: #007bff;
    font-weight: bold;
    text-decoration: none;
  }
  
  .email-link:hover {
    text-decoration: underline;
  }
  
  .social-link {
    color: #007bff;
    font-weight: bold;
    text-decoration: none;
    display: block;
    margin-bottom: 0.5rem;
  }
  
  .social-link:hover {
    text-decoration: underline;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1.5rem;
  }
  
  .contact-form input,
  .contact-form textarea {
    padding: 1rem;
    background: #444;
    border: none;
    border-radius: 8px;
    color: #fff;
    font-size: 1rem;
  }
  
  .contact-form textarea {
    height: 150px;
    resize: none;
  }
  
  .submit-btn {
    padding: 1rem;
    background: #007bff;
    border: none;
    border-radius: 8px;
    color: white;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-btn:hover {
    background-color: #007bff; /* Darker green on hover */
  }
  .phone-link {
    font-family: 'Roboto', Georgia;
    color: #007bff;
    font-weight: bold;
    text-decoration: none;
  }
  
  .phone-link:hover {
    text-decoration: underline;
  }